import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "./password.css";
import { useNavigate } from "react-router-dom";
import { forgotpass, forgotuser } from "./action";
import { forgotpassdata, forgotuserdata } from "../../services/loginservice";

function Password() {
  const [user, setUser] = useState("");
  const [email, setEmail] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const url = window.location.href;
    const last = url.split("/");
    const forgot = last[last.length - 1];
    if (forgot === "forgotusername") {
      setIsLoading(false);
    } else if (forgot === "forgotpassword") {
      setIsLoading(true);
    }
  });
  function Submitlogin(e) {
    e.preventDefault();
    setErrorMessage("");

    const tempData = {
      username: user,
    };

    if (tempData.username !== "") {
      forgotpassdata(tempData).then((response) => {
        if (response.status === true) {
          dispatch(
            forgotpass({
              user: user,
            })
          );
          setErrorMessage(response.message);
        } else {
          setErrorMessage(response.message);
        }
      });
    } else {
      setErrorMessage("Please Enter Your Username");
    }
  }

  function Submitemail(e) {
    e.preventDefault();
    setErrorMessage("");
    const tempData = {
      email: email,
    };

    if (tempData.email !== "") {
      forgotuserdata(tempData).then((response) => {
        if (response.status === true) {
          dispatch(
            forgotuser({
              email: user,
            })
          );
          setErrorMessage(response.message);
        } else {
          setErrorMessage(response.message);
        }
      });
    } else {
      setErrorMessage("Please Enter Your Regitered Email Address");
    }
  }
  return (
    <div>
      <main id="loginpage">
        <div className="container">
          <div className="loginpage_logo">
            <img
              src={require("../../assets/images/logo/logo.png")}
              height="60"
              alt="AFI"
              onClick={() => {
                navigate("/login");
              }}
            />
          </div>
          <div className="loginpage_section">
            <div className="loginpage_secionone">
              <div className="loginpage_sectionone_left">
                <h1>Athletics Federation of India</h1>
              </div>
            </div>

            <div className="loginpage_seciontwo">
              <div className="loginpage_sectiontwo_form">
                {isLoading && (
                  <form className="form">
                    <h6 className="text-center">
                      Welcome to Athletics Federation of India!
                    </h6>
                    <h2 className="text-center">Forgot Password</h2>
                    <fieldset className="username">
                      <label>Username</label>
                      <input
                        type="text"
                        placeholder="Enter your Username"
                        value={user}
                        onChange={(e) => setUser(e.target.value)}
                        autoComplete="off"
                      />
                      {errorMessage && (
                        <span className="text-danger"> {errorMessage} </span>
                      )}
                    </fieldset>

                    <button type="submit" className="btn" onClick={Submitlogin}>
                      Forgot Password
                    </button>
                    <div className="text-right">
                      <a
                        className=""
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate("/login");
                        }}
                      >
                        Login
                      </a>
                    </div>
                  </form>
                )}

                {/* Forgot Username Conditions */}
                {!isLoading && (
                  <form className="form">
                    <h6 className="text-center">
                      Welcome to Athletics Federation of India!
                    </h6>
                    <h2 className="text-center">Forgot Username</h2>
                    <fieldset className="username">
                      <label>Registered Email Address</label>
                      <input
                        type="text"
                        placeholder="Enter your Registered Email Address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        autoComplete="off"
                      />
                      {errorMessage && (
                        <span className="text-danger"> {errorMessage} </span>
                      )}
                    </fieldset>

                    <button type="submit" className="btn" onClick={Submitemail}>
                      Forgot Username
                    </button>
                    <div className="text-right">
                      <a
                        className=""
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate("/login");
                        }}
                      >
                        Login
                      </a>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
          <div className="loginpage_footer">
            <div className="loginpage_howtoregis"></div>
            <div className="copyrights">
              <span>© 2023 Athletics Federation of India</span>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Password;
